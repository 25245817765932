export const mapUserSentencesData = (data) => {
  if (data.allUserSentencesWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        targetLanguage: 'por',
        target: 'Não foram encontradas frases correspondentes',
        targetId: null,
        targetType: null,
      }
    ]
  } else {
    return data.allUserSentencesWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        targetLanguage: item.translations[0].lang,
        target: item.translations[0].text,
        targetId: item.translations[0].id,
        targetType: item.translations[0].__typename,
      }
    })
  }
}

export const mapUserSentencesDataOneToMany = (data) => {
  if (data.allUserSentencesWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        translations: [
          {
            targetLanguage: 'por',
            target: 'Não foram encontradas frases correspondentes',
            targetId: null,
            targetType: null,
          }
        ]
      }
    ];
  } else {
    return data.allUserSentencesWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        translations: item.translations.map((translation) => ({
          targetLanguage: translation.lang,
          target: translation.text,
          targetId: translation.id,
          targetType: translation.__typename,
        }))
      };
    });
  }
};

export const mapUserSentencesDataSpecificLang = (data) => {
  if (data.userSentencesWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        targetLanguage: 'por',
        target: 'Não foram encontradas frases correspondentes',
        targetId: null,
        targetType: null,
      }
    ]
  } else {
    return data.userSentencesWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        targetLanguage: item.translations[0].lang,
        target: item.translations[0].text,
        targetId: item.translations[0].id,
        targetType: item.translations[0].__typename,
      }
    })
  }
}


export const mapSharedSentencesDataSpecificLang = (data) => {
  if (data.sharedSentencesWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        targetLanguage: 'por',
        target: 'Não foram encontradas frases correspondentes',
        targetId: null,
        targetType: null,
      }
    ]
  } else {
    return data.sharedSentencesWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        targetLanguage: item.translations[0].lang,
        target: item.translations[0].text,
        targetId: item.translations[0].id,
        targetType: item.translations[0].__typename,
      }
    })
  }
}

export const mapUserSentencesDataSpecificLangOneToMany = (data) => {
  if (data.userSentencesWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        translations: [
          {
            targetLanguage: 'por',
            target: 'Não foram encontradas frases correspondentes',
            targetId: null,
            targetType: null,
          }
        ]
      }
    ];
  } else {
    return data.userSentencesWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        translations: item.translations.map((translation) => ({
          targetLanguage: translation.lang,
          target: translation.text,
          targetId: translation.id,
          targetType: translation.__typename,
        }))
      };
    });
  }
};


export const mapTatoebaSentencesData = (data) => {
  if (data.SentenceWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        targetLanguage: 'por',
        target: 'Não foram encontradas frases correspondentes',
        targetId: null,
        targetType: null,
      }
    ]
  } else {
    return data.SentenceWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        targetLanguage: item.translations[0].lang,
        target: item.translations[0].text,
        targetId: item.translations[0].id,
        targetType: item.translations[0].__typename,
      }
    })
  }
}

export const mapTatoebaSentencesDataOneToMany = (data) => {
  if (data.SentenceWithTranslations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        translations: [
          {
            targetLanguage: 'por',
            target: 'Não foram encontradas frases correspondentes',
            targetId: null,
            targetType: null,
          }
        ]
      }
    ];
  } else {
    return data.SentenceWithTranslations.map((item) => {
      return {
        sourceLanguage: item.sentence.lang,
        source: item.sentence.text,
        sourceId: item.sentence.id,
        sourceType: item.sentence.__typename,
        translations: item.translations.map((translation) => ({
          targetLanguage: translation.lang,
          target: translation.text,
          targetId: translation.id,
          targetType: translation.__typename,
        }))
      };
    });
  }
};


export const mapInspirationData = (data) => {
  if (data.getInspirations.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching Inspirations found, please add one',
        sourceId: null,
        sourceType: null,
      }
    ]
  } else {
    return data.getInspirations.map((item) => {
      return {
        sourceLanguage: item.properties.lang,
        source: item.properties.text,
        sourceId: item.properties.id,
        sourceType: item.properties.__typename,
      }
    })
  }
}

export const mapProjectData = (data) => {
  if (data.getProjects.length === 0) {
    return [
      {
        sourceLanguage: 'eng',
        source: 'No matching sentences found',
        sourceId: null,
        sourceType: null,
        targetLanguage: 'por',
        target: 'Não foram encontradas frases correspondentes',
        targetId: null,
        targetType: null,
      }
    ]
  } else {
    return data.getProjects.map((item) => {
      return {
        sourceLanguage: item.properties.lang,
        source: item.properties.title,
        sourceId: item.properties.id,
        sourceType: item.properties.__typename,
        targetLanguage: item.properties.lang,
        target: item.properties.title,
        targetId: item.properties.id,
        targetType: item.properties.__typename,
      }
    })
  }
}
