import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/system'
import Downshift from 'downshift'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5, 0.25),
  backgroundColor: 'black', // Set the background color to black
  color: 'white',           // Set the text color to white
  '& .MuiChip-deleteIcon': {
    color: 'white',         // Default delete icon color
  },
  '&:hover': {
    backgroundColor: 'black', // Maintain black background on hover
    '& .MuiChip-deleteIcon': {
      color: '#D3D3D3',         // Ensure delete icon remains white on hover
    },
  },
}));


export default function UsersInput({ selectedUsers, placeholder, users, ...other }) {
  const [inputValue, setInputValue] = React.useState('')

  // Handle pressing the Enter key to add a new user
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const inputValueTrimmed = event.target.value.trim()

      if (!inputValueTrimmed) return // Prevent empty inputs

      // Avoid adding duplicates
      if (!users.includes(inputValueTrimmed)) {
        selectedUsers([...users, inputValueTrimmed]) // Notify parent of updated users
      }

      setInputValue('')
    }

    // Remove last user on Backspace when input is empty
    if (users.length && !inputValue.length && event.key === 'Backspace') {
      selectedUsers(users.slice(0, users.length - 1)) // Notify parent of updated users
    }
  }

  // Add selected user manually
  function handleChange(item) {
    if (!users.includes(item)) {
      selectedUsers([...users, item]) // Notify parent of updated users
    }
    setInputValue('')
  }

  // Remove a user chip
  const handleDelete = (item) => () => {
    selectedUsers(users.filter((user) => user !== item)) // Notify parent of updated users
  }

  // Update input value
  function handleInputChange(event) {
    setInputValue(event.target.value)
  }

  return (
    <React.Fragment>
      <Downshift id="downshift-users-input" inputValue={inputValue} onChange={handleChange}>
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder
          })

          return (
            <div>
              <TextField
                InputProps={{
                  startAdornment: users.map((user) => (
                    <StyledChip key={user} tabIndex={-1} label={user} onDelete={handleDelete(user)} />
                  )),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus
                }}
                {...other}
                {...inputProps}
              />
            </div>
          )
        }}
      </Downshift>
    </React.Fragment>
  )
}

UsersInput.defaultProps = {
  users: []
}

UsersInput.propTypes = {
  selectedUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string
}
