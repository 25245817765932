import * as React from 'react'
import { useRef, useState, useContext, useCallback, useEffect } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText' // Import SplitText plugin
import { useGSAP } from '@gsap/react'
import styles from './TranslationFullView.module.scss'
import voices from '../speech/voices'
import { speak } from '../speech/speak.js'
import DeviceContext from '../context/DeviceContext'

gsap.registerPlugin(SplitText)

export default function TranslationFullView({ currentTranslation, sentenceList, voiceSpeed, isAllViewTranslationVisible, setIsAllViewTranslationVisible }) {
  const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0)
  const [speakAll, setSpeakAll] = useState(false)
  const { isMobile } = useContext(DeviceContext)
  const randomTimerRef = useRef(null) // Reference to hold the timer for changing the translation index automatically.

  console.log('Speak all', speakAll)
  const targetRef = useRef(null)
  const sourceRef = useRef(null)

  const swipeStartX = useRef(0)
  const swipeEndX = useRef(0)

  const swipeStartY = useRef(0) // For vertical swipe detection
  const swipeEndY = useRef(0) // For vertical swipe detection

  useGSAP(() => {
    if (targetRef.current && sourceRef.current) {
      // Split the target text into words
      const splitTarget = new SplitText(targetRef.current, {
        type: 'words'
      })

      // Split the source text into words
      const splitSource = new SplitText(sourceRef.current, {
        type: 'words'
      })

      // Animate the target text words
      gsap.from(splitTarget.words, {
        opacity: 0,
        y: 50,
        stagger: 0.1,
        duration: 2,
        ease: 'power1.out'
      })

      // Animate the source text words
      gsap.from(splitSource.words, {
        opacity: 0,
        y: 50,
        stagger: 0.1,
        duration: 2,
        delay: 1,
        ease: 'power1.out'
      })

      // Clean up the SplitText instances
      return () => {
        splitTarget.revert()
        splitSource.revert()
      }
    }
  }, [currentTranslationIndex])

  const handleClickTarget = useCallback(() => {
    speak(sentenceList[currentTranslationIndex].target, voices[sentenceList[currentTranslationIndex].targetLanguage], voiceSpeed)
  }, [voiceSpeed, sentenceList, currentTranslationIndex])

  const handleClickSource = useCallback(() => {
    speak(sentenceList[currentTranslationIndex].source, voices[sentenceList[currentTranslationIndex].sourceLanguage], voiceSpeed)
  }, [voiceSpeed, sentenceList, currentTranslationIndex])

  // Swipe handling functions
  const handleTouchStart = (e) => {
    swipeStartX.current = e.touches[0].clientX
    swipeStartY.current = e.touches[0].clientY // Track vertical start
  }

  const handleTouchMove = (e) => {
    swipeEndX.current = e.touches[0].clientX
    swipeEndY.current = e.touches[0].clientY // Track vertical move
  }

  const handleTouchEnd = () => {
    const swipeDistanceX = swipeEndX.current - swipeStartX.current
    const swipeDistanceY = swipeEndY.current - swipeStartY.current // Calculate vertical swipe distance

    // Handle horizontal swipes
    if (swipeDistanceX > 50) {
      // Swipe right
      setCurrentTranslationIndex((prevIndex) => (prevIndex - 1 < 0 ? sentenceList.length - 1 : prevIndex - 1))
    } else if (swipeDistanceX < -50) {
      // Swipe left
      setCurrentTranslationIndex((prevIndex) => (prevIndex + 1 === sentenceList.length ? 0 : prevIndex + 1))
    }

    // Handle vertical swipe up
    if (swipeDistanceY < -50) {
      setIsAllViewTranslationVisible(false) // Trigger action on swipe-up
    }
  }

  // Handle key presses for desktop
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isMobile && isAllViewTranslationVisible) {
        if (event.key === 'ArrowRight') {
          // Move to the next sentence
          setCurrentTranslationIndex((prevIndex) => (prevIndex + 1 === sentenceList.length ? 0 : prevIndex + 1))
        } else if (event.key === 'ArrowLeft') {
          // Move to the previous sentence
          setCurrentTranslationIndex((prevIndex) => (prevIndex - 1 < 0 ? sentenceList.length - 1 : prevIndex - 1))
        } else if (event.key === 'e') {
          setIsAllViewTranslationVisible(false)
        } else if (event.key === 's' || event.key === 'S') {
          handleClickTarget()
        } else if (event.key === 'a' || event.key === 'A') {
          setSpeakAll((prevIndex) => (prevIndex === true ? false : true))
        } else if (event.key === 'r' || event.key === 'R') {
          // Select a random sentence
          const randomIndex = Math.floor(Math.random() * sentenceList.length)
          setCurrentTranslationIndex(randomIndex)
        } else if (event.key === 'g' || event.key === 'G') {
          // On first press of "g", set a random translation index and start changing every 30 seconds
          const randomIndex = Math.floor(Math.random() * sentenceList.length)
          setCurrentTranslationIndex(randomIndex)

          if (randomTimerRef.current) {
            // Clear the timer if it's already running
            clearInterval(randomTimerRef.current)
            randomTimerRef.current = null
          } else {
            // Set the interval to change the index every 30 seconds
            randomTimerRef.current = setInterval(() => {
              const newRandomIndex = Math.floor(Math.random() * sentenceList.length)
              if (speakAll === true) {
                speak(sentenceList[newRandomIndex].target, voices[sentenceList[newRandomIndex].targetLanguage], voiceSpeed)
              }
              setCurrentTranslationIndex(newRandomIndex)
            }, 20000)
          }
        }
      }
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup event listener and timer on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      if (randomTimerRef.current) {
        clearInterval(randomTimerRef.current)
      }
    }
  }, [sentenceList.length, isMobile, isAllViewTranslationVisible, speakAll, currentTranslationIndex])

  return (
    <div
      className={styles.translationFullView}
      onTouchStart={isMobile ? handleTouchStart : null}
      onTouchMove={isMobile ? handleTouchMove : null}
      onTouchEnd={isMobile ? handleTouchEnd : null}>
      <div className={styles.translation}>
        <span
          ref={targetRef}
          className={styles.textNormal}
          onClick={isMobile ? undefined : handleClickTarget}
          onPointerUp={isMobile ? handleClickTarget : undefined}>
          {sentenceList[currentTranslationIndex].target}
        </span>
      </div>
      <div className={styles.source}>
        <span
          ref={sourceRef}
          className={styles.textNormal}
          onClick={isMobile ? undefined : handleClickSource}
          onPointerUp={isMobile ? handleClickSource : undefined}>
          {sentenceList[currentTranslationIndex].source}
        </span>
      </div>
    </div>
  )
}
