import * as React from 'react'
import { useRef, useState, useContext, useCallback, useEffect } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText' // Import SplitText plugin
import { useGSAP } from '@gsap/react'
import styles from './SpeechFullView.module.scss'
import voices from '../speech/voices.js'
import { speak } from '../speech/speak.js'
import DeviceContext from '../context/DeviceContext.js'
import SpeechRecognitionComponent from '../speech/speechRecognition.js'
import languages from '../languages.js'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { getDeeplLanguageAcronym } from '../query/deeplLanguages.js'
import { useMutation, gql } from '@apollo/client'
import UserContext from '../context/UserContext.js'
import translate from 'deepl'

const ADD_SENTENCE_MUTATION = gql`
  mutation AddUserSentence($username: String!, $sourceText: String!, $targetText: String!, $sourceLang: String!, $targetLang: String!, $tags: [String]!) {
    AddUserSentence(username: $username, sourceText: $sourceText, targetText: $targetText, sourceLang: $sourceLang, targetLang: $targetLang, tags: $tags) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`

gsap.registerPlugin(SplitText)

export default function SpeechFullView({ currentTranslation, sentenceList, voiceSpeed, isAllViewSpeechVisible, setIsAllViewSpeechVisible }) {
  // Use context
  const [notification, setNotification] = useState({ message: '', visible: false })
  const { username, isLoggedIn } = useContext(UserContext)

  const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0)

  const [spokenText, setSpokenText] = useState('')
  const [spokenLang, setSpokenLang] = useState('eng')
  const [targetSentence, setTargetSentence] = useState(null)
  const [targetLanguage, setTargetLanguage] = useState(Object.keys(languages)[1])

  const { isMobile } = useContext(DeviceContext)

  const handleLanguageChange = (event) => {
    setTargetLanguage(event.target.value)
  }

  const targetRef = useRef(null)
  const sourceRef = useRef(null)

  const swipeStartX = useRef(0)
  const swipeEndX = useRef(0)

  const swipeStartY = useRef(0) // For vertical swipe detection
  const swipeEndY = useRef(0) // For vertical swipe detection
  // GraphQL
  const [addUserSentence, { data, loading, error }] = useMutation(ADD_SENTENCE_MUTATION)
  const [tags, setTags] = useState([])

  const handleSave = async () => {
    try {
      const { data } = await addUserSentence({
        variables: {
          username: username,
          sourceText: spokenText,
          targetText: targetSentence,
          sourceLang: spokenLang,
          targetLang: targetLanguage,
          tags: tags
        }
      })
      setNotification({ message: 'Sentence saved successfully!', visible: true })
      setTimeout(() => setNotification({ ...notification, visible: false }), 3000) // Hide after 3 seconds
    } catch (error) {
      setNotification({ message: 'Failed to save the sentence.', visible: true })
      setTimeout(() => setNotification({ ...notification, visible: false }), 3000)
    }
  }

  useGSAP(() => {
    if (targetRef.current && sourceRef.current) {
      // Split the target text into words
      const splitTarget = new SplitText(targetRef.current, {
        type: 'words'
      })

      // Split the source text into words
      const splitSource = new SplitText(sourceRef.current, {
        type: 'words'
      })

      // Animate the target text words
      gsap.from(splitTarget.words, {
        opacity: 0,
        y: 50,
        stagger: 0.1,
        duration: 2,
        ease: 'power1.out'
      })

      // Animate the source text words
      gsap.from(splitSource.words, {
        opacity: 0,
        y: 50,
        stagger: 0.1,
        duration: 2,
        delay: 1,
        ease: 'power1.out'
      })

      // Clean up the SplitText instances
      return () => {
        splitTarget.revert()
        splitSource.revert()
      }
    }
  }, [currentTranslationIndex, spokenText, targetSentence])

  const handleClickTarget = useCallback(() => {
    speak(spokenText, voices['eng'], voiceSpeed)
  }, [voiceSpeed, spokenText])

  const handleClickTranslation = useCallback(() => {
    speak(targetSentence, voices[targetLanguage], voiceSpeed)
  }, [voiceSpeed, targetSentence, targetLanguage])

  // Swipe handling functions
  const handleTouchStart = (e) => {
    swipeStartX.current = e.touches[0].clientX
    swipeStartY.current = e.touches[0].clientY // Track vertical start
  }

  const handleTouchMove = (e) => {
    swipeEndX.current = e.touches[0].clientX
    swipeEndY.current = e.touches[0].clientY // Track vertical move
  }

  const handleTouchEnd = () => {
    const swipeDistanceX = swipeEndX.current - swipeStartX.current
    const swipeDistanceY = swipeEndY.current - swipeStartY.current // Calculate vertical swipe distance

    // Handle horizontal swipes
    if (swipeDistanceX > 50) {
      // Swipe right
      setCurrentTranslationIndex((prevIndex) => (prevIndex - 1 < 0 ? sentenceList.length - 1 : prevIndex - 1))
    } else if (swipeDistanceX < -50) {
      // Swipe left
      setCurrentTranslationIndex((prevIndex) => (prevIndex + 1 === sentenceList.length ? 0 : prevIndex + 1))
    }

    // Handle vertical swipe up
    if (swipeDistanceY < -150) {
      setIsAllViewSpeechVisible(false) // Trigger action on swipe-up
    }
  }

  // Handle key presses for desktop
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isMobile && isAllViewSpeechVisible) {
        if (event.key === 'ArrowRight') {
          // Move to the next sentence
          setCurrentTranslationIndex((prevIndex) => (prevIndex + 1 === sentenceList.length ? 0 : prevIndex + 1))
        } else if (event.key === 'ArrowLeft') {
          // Move to the previous sentence
          setCurrentTranslationIndex((prevIndex) => (prevIndex - 1 < 0 ? sentenceList.length - 1 : prevIndex - 1))
        } else if (event.key === 'e') {
          setIsAllViewSpeechVisible(false)
        } else if (event.key === 's' || event.key === 'S') {
          handleClickTarget()
        } else if (event.key === 'r' || event.key === 'R') {
          // Select a random sentence
          const randomIndex = Math.floor(Math.random() * sentenceList.length)
          setCurrentTranslationIndex(randomIndex)
        }
      }
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [sentenceList.length, isMobile, isAllViewSpeechVisible])

  // Handle speech recognition result
  const handleSpeechResult = (text) => {
    setSpokenText(text) // Update spoken text in state
  }

  const translateText = () => {
    const sourceLangCode = getDeeplLanguageAcronym('eng')
    const targetLangCode = getDeeplLanguageAcronym(targetLanguage)

    if (!sourceLangCode || !targetLangCode) {
      console.error('Translation not supported for selected language')
      return
    }

    translate({
      free_api: true,
      text: spokenText,
      target_lang: targetLangCode,
      auth_key: process.env.REACT_APP_DEEPL_API_KEY // Ensure your API key is securely stored
    })
      .then((result) => {
        setTargetSentence(result.data.translations[0].text)
      })
      .catch((error) => {
        console.error('Translation error:', error)
      })
  }

  useEffect(() => {
    translateText()
  }, [spokenText])

  console.log(targetLanguage)

  return (
    <div
      className={styles.translationFullView}
      onTouchStart={isMobile ? handleTouchStart : null}
      onTouchMove={isMobile ? handleTouchMove : null}
      onTouchEnd={isMobile ? handleTouchEnd : null}>
      <div className={styles.spoken}>
        <span
          ref={targetRef}
          className={styles.textNormal}
          onClick={isMobile ? undefined : handleClickTarget}
          onPointerUp={isMobile ? handleClickTarget : undefined}>
          {spokenText}
        </span>
      </div>
      <div className={styles.button}>
        <p>Spoken Language</p>
        <SpeechRecognitionComponent setSpokenLang={setSpokenLang} spokenLang={spokenLang} onSpeechResult={handleSpeechResult} />
        <p>Translation Language:</p>
        <Select labelId="language-label" id="language" value={targetLanguage} onChange={handleLanguageChange} fullWidth>
          {Object.entries(languages).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <p>Spoken Text: {spokenText}</p>
        <p>Translation: {targetSentence}</p>
        <div onClick={handleSave}>Save Sentence</div>
        <div>{notification.visible && <div className={styles.notification}>{notification.message}</div>}</div>
      </div>
      <div className={styles.translation}>
        <span
          ref={sourceRef}
          className={styles.textNormal}
          onClick={isMobile ? undefined : handleClickTranslation}
          onPointerUp={isMobile ? handleClickTranslation : undefined}>
          {targetSentence}
        </span>
      </div>
    </div>
  )
}
