import { gql, useMutation } from '@apollo/client'

export const USER_SENTENCES_QUERY = gql`
  query AllUserSentencesWithTranslations($username: String!, $lang: String!, $relationships: [String!]!) {
    allUserSentencesWithTranslations(username: $username, lang: $lang, relationships: $relationships) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`

export const USER_SENTENCES_WITH_TRANSLATIONS_QUERY = gql`
  query UserSentencesWithTranslations($username: String!, $lang: String!, $translationLang: String!) {
    userSentencesWithTranslations(username: $username, lang: $lang, translationLang: $translationLang) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`

export const SHARED_SENTENCES_WITH_TRANSLATIONS_QUERY = gql`
  query SharedSentencesWithTranslations($username: String!, $lang: String!, $translationLang: String!) {
    sharedSentencesWithTranslations(username: $username, lang: $lang, translationLang: $translationLang) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`;


export const TATOEBA_SENTENCES_QUERY = gql`
  query SentenceWithTranslations($lang: String!, $word: String!, $translationLang: String!) {
    SentenceWithTranslations(lang: $lang, word: $word, translationLang: $translationLang) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`

export const GET_INSPIRATIONS_QUERY = gql`
  query GetInspirations($username: String!) {
    getInspirations(username: $username) {
      identity
      labels
      properties {
        text
        lang
        username
      }
      elementId
    }
  }
`

export const GET_PROJECTS_QUERY = gql`
  query GetProjects($username: String!) {
    getProjects(username: $username) {
      identity
      labels
      properties {
        title
        lang
        username
      }
      elementId
    }
  }
`

export const SAVE_SENTENCE_MUTATION = gql`
  mutation SaveSentence($username: String!, $sentenceId: ID!) {
    SaveSentence(username: $username, sentenceId: $sentenceId) {
      id
      text
      lang
    }
  }
`

export const CHECK_SAVED_STATUS_QUERY = gql`
  query CheckSavedStatus($username: String!, $sourceSentenceId: ID!, $translationSentenceId: ID!) {
    checkSavedStatus(username: $username, sourceSentenceId: $sourceSentenceId, translationSentenceId: $translationSentenceId) {
      sourceSaved
      translationSaved
    }
  }
`

export const SAVE_SENTENCES_MUTATION = gql`
  mutation SaveSentences($username: String!, $sourceSentenceId: ID!, $translationSentenceId: ID!) {
    saveSentences(username: $username, sourceSentenceId: $sourceSentenceId, translationSentenceId: $translationSentenceId) {
      sourceSentence {
        id
      }
      sourceSaved
      translationSentence {
        id
      }
      translationSaved
    }
  }
`

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        id
        username
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register($username: String!, $password: String!) {
    register(username: $username, password: $password) {
      id
      username
    }
  }
`;