import * as React from 'react'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import AbcIcon from '@mui/icons-material/Abc'
import TranslateIcon from '@mui/icons-material/Translate'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function HallucinationButton({ toggleViewHallucination, toggleViewTranslation, toggleViewSpeech, toggleViewManyInspirations }) {
  const actions = [
    { icon: <AbcIcon />, name: 'Inspirations View', onClick: () => toggleViewHallucination() },
    { icon: <TranslateIcon />, name: 'Translation View', onClick: () => toggleViewTranslation() },
    { icon: <RecordVoiceOverIcon />, name: 'Speech View', onClick: () => toggleViewSpeech() }
    // { icon: <AbcIcon />, name: 'Many Inspirations', onClick: () => toggleViewManyInspirations() }
  ]

  return (
    <SpeedDial direction="up" ariaLabel="SpeedDialLanguage" icon={< PlayArrowIcon />}>
      {actions.map((action) => (
        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.onClick} />
      ))}
    </SpeedDial>
  )
}
