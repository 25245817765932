const flags = {
  eng: '🇬🇧',
  rus: '🇷🇺',
  ita: '🇮🇹',
  deu: '🇩🇪',
  fra: '🇫🇷',
  por: '🇵🇹',
  spa: '🇪🇸',
  jpn: '🇯🇵',
  nld: '🇳🇱',
  cmn: '🇨🇳',
  vie: '🇻🇳',
  kor: '🇰🇷',
  ara: '🇸🇦',
  ell: '🇬🇷',
  swe: '🇸🇪',
  tur: '🇹🇷',
  geo: '🇬🇪',
}

export default flags
