const languages = {
  eng: 'English',
  rus: 'Russian',
  ita: 'Italian',
  por: 'Portuguese',
  tur: 'Turkish',
  geo: 'Georgian',
  spa: 'Spanish',
  swe: 'Swedish',
  nld: 'Dutch',
  cmn: 'Mandarin Chinese',
  vie: 'Vietnamese',
  kor: 'Korean',
  ara: 'Arabic',
  jpn: 'Japanese',
  ell: 'Greek',
  deu: 'German',
  fra: 'French',
}

export const getLanguageAcronym = (languageName) => {
  return Object.keys(languages).find((key) => languages[key] === languageName)
}

export default languages
